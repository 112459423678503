import React from 'react'
// import { useEffect } from 'react'
import Layout from '../components/layout';
import '../css/resultsTablesEtc.css'
import InnerHTML from 'dangerously-set-html-content'


export default function Matchcenter() {

  const html = `
  <script async src="https://www.scorebar.com/widget/" data-scorebar></script>

  

`

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://www.scorebar.com/widget/";
  //   script.async = true;
  //   script.setAttribute('data-scorebar', '');
  //   document.getElementById('matchs-details-container').appendChild(script);

  // }, []);

  return (
    <Layout>
    <div>
      <div id='matchs-details-container'></div>
      <InnerHTML html={html} />
    </div>
    </Layout>
  )
}
